import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Icons
import DownloadIcon from "assets/icons/icon-download.svg"

// Components
import Container from "components/container"

// Downloads
import QuickReferenceGuide from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Test-Quick-Reference-Guide.pdf"
import InstructionsForUse from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Instructions-For-Use.pdf"
import Anterior from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Test-Anterior-Nasal-Specimen-Collection-Instructions.pdf"
import SpecificationSheet from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Test-Specification-Sheet.pdf"
import Nasopharyngeal from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Test-Point-of-Care-Nasopharyngeal-Collection-Instructions.pdf"
import ExternalControlsIFU from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Test-External-Controls-Instructions-For-Use.pdf"
import FactSheetHealthcare from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Fact-Sheet-For-Healthcare-Providers.pdf"
import FactSheetPatient from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Fact-Sheet-For-Patients.pdf"
import SDSControlSwabs from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-SDS-Control-Swabs.pdf"
import DSDSSolidReagents from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-DSDS-Solid-Reagents.pdf"
import DSDSLiqidReagents from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-DSDS-Liquid Reagents.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap; 
`

const StyledResources = styled.section`
    padding: 80px 10px;
    
	  ${breakpoint.small`
		flex-wrap: nowrap;
		padding: 120px 20px;
	  `}

  .block {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
 	column-gap: 40px;

    ${breakpoint.small`
      flex-wrap: nowrap;
    `}

    &--image {
      	width: 100%;
      	margin: 0 auto;
		display: flex;
		justify-content: center;

		${breakpoint.small`
			display: block;
			margin-top: 50px;
			width: 40%;
      		max-width: 550px;
		`}

		.imageResize {
			max-width: 450px;

			${breakpoint.small`
				max-width: 550px;
			`}
		}

	 }

    &--content {
		width: 100%;
	
		${breakpoint.small`
			width: 60%;
		`}

		h2 {
			margin-bottom: 40px;
			text-align: center;
			
			${breakpoint.small`
				text-align: left;
			`}
		}

		p {

			&:first-of-type {
			padding-bottom: 2rem;
			}
		}
	  
			.resources {
				padding-bottom: 2rem;
				margin-left: 2px;
				
				ul {
					max-width: 400px;

					${breakpoint.large`
						max-width: 500px;
					`}
				}
				
				li {
					line-height: 24px;
					margin-bottom: 10px;
	
					&:last-child {
						margin-bottom: 0;
					}
				
					a {
						color: ${colors.charcoal};
						font-size: 15px;
						display: flex;
						align-items: flex-start;
					
						&::before {
							content: url(${DownloadIcon});
							position: relative;
							top: 4px;
							margin-right: 12px;
							margin-top: -5px;
						}
					
						${breakpoint.medium`
						font-size: 17px;
							`}
				
				
						&:hover {
							color: ${colors.orange};
						}
					
					}
				}
			}
		}
  } 

	  .fda {
		margin-top: 20px;
		font-size: 10px;
		font-style: italic;
		line-height: 1.4em;

		${breakpoint.medium`
			margin-top: 40px;
			`}

	}

`

const Resources = () => {
	return (
		<StyledResources id="downloads">
			<StyledContainer>
				<div className="block">
					<div className="block--content">
						<h2 data-aos="fade-up" data-aos-once="false">Downloads</h2>
						<p data-aos="fade-up" data-aos-once="false" data-aos-delay="100">
						Visby Medical developed PCR easy enough to be used by almost anyone, in any CLIA-waived setting. Below are resources to help run a successful test.
						</p>
						<ul className="resources" data-aos="fade-up" data-aos-once="false" data-aos-delay="200">
							<li>
								<a href={QuickReferenceGuide} target="_blank" rel="noopener noreferrer">Quick Reference Guide</a>
							</li>
							<li>
								<a href={InstructionsForUse} target="_blank" rel="noopener noreferrer">Instructions for Use</a>
							</li>
							<li>
								<a href={Anterior} target="_blank" rel="noopener noreferrer">Anterior Nasal Specimen Collection Instructions</a>
							</li>
							<li>
								<a href={Nasopharyngeal} target="_blank" rel="noopener noreferrer">Nasopharyngeal Specimen Collection Instructions</a>
							</li>
							<li>
								<a href={ExternalControlsIFU} target="_blank" rel="noopener noreferrer">External Controls-Instructions for Use</a>
							</li>
							<li>
								<a href={FactSheetHealthcare} target="_blank" rel="noopener noreferrer">Fact Sheet for Healthcare Providers</a>
							</li>
							<li>
								<a href={FactSheetPatient} target="_blank" rel="noopener noreferrer">Fact Sheet for Patients</a>
							</li>
							<li>
								<a href={SDSControlSwabs} target="_blank" rel="noopener noreferrer">Safety Data Sheet - Control Swabs</a>
							</li>
							<li>
								<a href={DSDSSolidReagents} target="_blank" rel="noopener noreferrer">Device Safety Data Sheet – Solid Reagents</a>
							</li>
							<li>
								<a href={DSDSLiqidReagents} target="_blank" rel="noopener noreferrer">Device Safety Data Sheet – Liquid Reagents</a>
							</li>
							<li>
								<a href={SpecificationSheet} target="_blank" rel="noopener noreferrer">Specification Sheet</a>
							</li>
						</ul>
					</div>

					<div className="block--image" data-aos="fade-up" data-aos-delay="200" data-aos-once="false">
						<StaticImage
							src="../../../assets/images/respiratory-health-test/device-sketch.svg"
							width={600}
							alt="Visby Medical Respiratory Health Test"
							className="imageResize"
						/>
					</div>
				</div>
				<p className="fda" data-aos="fade-up" data-aos-delay="300" data-aos-once="false">This product has not been FDA cleared or approved, but has been authorized for emergency use by FDA under an EUA for 
						use by authorized laboratories. This product has been authorized only for the detection and differentiation of nucleic 
						acid from SARS-CoV-2, influenza A, and influenza B, not for any other viruses or pathogens; and the emergency use of 
						this product is only authorized for the duration of the declaration that circumstances exist justifying the 
						authorization of emergency use of in vitro diagnostics for detection and/or diagnosis of COVID-19 under Section 564(b)(1) 
						of the Federal Food, Drug, and Cosmetic Act, 21 U.S.C. § 360bbb-3(b)(1), unless the declaration is terminated or authorization is revoked sooner.</p>
			</StyledContainer>
		</StyledResources>
	)
}

export default Resources
